@import "../../stylesheets/utilities";

.ourCourses {
    width: 100%;
    min-height: 30vh;

    &_container {
        width: 100%;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: var(--padding);

        @media #{$xsmall} {
            padding-left: 40px;
        }
    }

    &_title {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: 36px;
        font-weight: font-weight(light);
        padding: 60px 0;
    }

    &_content {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        margin-bottom: 60px;
    }

    &_contentTile {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        margin-bottom: 60px;
        justify-items: center;
    }

    &_card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: var(--ourcourse-card-align, center);
        border-radius: 10px;
        background-color: var(--ourcourse-card-bg);
        color: $white;
        padding: var(--ourcourse-card-pad, 30px 20px);
        text-align: center;
        margin: var(--ourcourse-card-m, 0 20px 70px 10px);
        transition: transform 0.3s, filter 0.3s;
        cursor: pointer;

        &:nth-child(1) {
            --ourcourse-card-bg: #{$blue2};
        }
        &:nth-child(2) {
            --ourcourse-card-bg: #{$green};
        }
        &:nth-child(3) {
            --ourcourse-card-bg: #{$dark};
        }

        @media #{$small} {
            &:hover {
                --ourcourse-arrow-anim: running;
                --ourcourse-arrow-text: 1;
                transform: translateY(-20px);
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }
        }

        @media #{$xsmall} {
            --ourcourse-card-pad: 30px 60px;
            --ourcourse-card-m: 10px 0;
            --ourcourse-detail-display: none;
            --ourcourse-title-align: left;
            --ourcourse-logo-size: 45px;
            --ourcourse-card-title-size: 14px;
            --ourcourse-card-title-mb: 0;
            --ourcourse-card-align: flex-start;
        }

        &_title {
            font-size: var(--ourcourse-card-title-size, 26px);
            font-weight: font-weight(bold);
            margin-bottom: var(--ourcourse-card-title-mb, 30px);
            position: relative;
            text-align: var(--ourcourse-title-align, center);

            &::after {
                display: var(--ourcourse-detail-display, block);
                content: "";
                position: absolute;
                bottom: -15px;
                left: 30%;
                width: 40%;
                height: 1px;
                background-color: $white;
            }
        }

        &_content {
            display: var(--ourcourse-detail-display, block);
            font-size: 14px;
            font-weight: font-weight(regular);
            line-height: 22px;
            margin-bottom: 50px;
        }

        &_logo {
            position: absolute;
            bottom: 0;
            width: var(--ourcourse-logo-size, 100px);
            height: var(--ourcourse-logo-size, 100px);
            background-color: $white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(50px);

            @media #{$xsmall} {
                left: -25px;
                bottom: calc(50% + 23px);
                border: solid 1px var(--ourcourse-card-bg);
            }

            > img {
                width: 50%;
            }
        }

        > i {
            position: absolute;
            right: 20px;
            bottom: 30px;
            font-size: 20px;
            color: $white;
            transform: rotate(180deg);
            animation: arrow_back 0.5s infinite alternate;
            animation-play-state: var(--ourcourse-arrow-anim, paused);

            &::after {
                opacity: var(--ourcourse-arrow-text, 0);
                content: attr(title);
                position: absolute;
                left: 25px;
                top: 4px;
                width: max-content;
                font-size: 12px;
                font-family: "Noto Sans";
                text-transform: capitalize;
                transform: rotate(180deg);
                transition: opacity 0.3s;
            }

            @media #{$xsmall} {
                font-size: 30px;
                right: 15px;
                bottom: calc(50% - 15px);
            }
        }
    }
}

@keyframes arrow_back {
    to {
        transform: rotate(180deg) translateX(5px);
    }
}
.coursesTiles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    min-height: 30vh;
}
.atsPath {
    width: 100%;
    padding: 60px var(--padding) 80px;
    background-color: $dark;
    position: relative;

    @media #{$xsmall} {
        --path-bottom-display: none;
        --path-container-maxw: 400px;
        --path-tooltip-display: block;
    }

    &_container {
        width: 100%;
        max-width: var(--path-container-maxw, $maxWidth);
        margin: 0 auto;
    }

    &_top {
        width: 100%;

        @media #{$xsmall} {
            padding: 0 20px;
        }

        > p {
            font-size: 18px;
            font-weight: font-weight(bold);
            color: $light_blue;
            text-transform: uppercase;
        }

        > h2 {
            font-size: 32px;
            font-weight: font-weight(bold);
            color: $white;
            text-transform: uppercase;
        }
    }

    &_content {
        display: flex;
        gap: 25px;
        padding: 40px var(--path-content-pad-x) 20px;

        @media #{$xsmall} {
            flex-direction: column;
            --path-content-pad-x: 20px;
        }
    }

    &_item {
        z-index: 50;
        flex: 1 1 100%;
        background-color: $white;
        padding: 0 20px;
        position: relative;
        transform: skew(-5deg);

        &_content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            transform: skew(5deg);
        }

        &_title {
            max-width: 190px;
            font-size: 16px;
            font-weight: font-weight(bold);
            text-transform: uppercase;
            color: $light_blue;
            padding: 20px 0;
        }

        &_circle {
            width: 100px;
            height: 50px;
            color: $white;
            position: relative;
            overflow: hidden;

            &::after {
                content: attr(title);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 200%;
                border-radius: 50%;
                background-color: $light_blue;
                font-size: 14px;
                font-weight: font-weight(bold);
                text-transform: uppercase;
                padding-top: 25px;
            }
        }

        &_tooltip {
            display: var(--path-tooltip-display, none);
            width: 25px;
            height: 25px;
            position: absolute;
            top: 0px;
            right: -25px;
            padding: 5px;

            &:hover {
                --path-item-tooltip-opacity: 1;
                --path-item-tooltip-events: all;
            }
            &_box {
                opacity: var(--path-item-tooltip-opacity, 0);
                width: max-content;
                position: absolute;
                top: 30px;
                right: 0;
                background-color: $sky_blue;
                padding: 10px;
                font-size: 12px;
                border-radius: 5px;
                transition: opacity 0.3s;
                pointer-events: var(--path-item-tooltip-events, none);

                &::after {
                    content: "";
                    width: 10px;
                    height: 5px;
                    position: absolute;
                    right: 7px;
                    top: -5px;
                    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                    background-color: $sky_blue;
                }
            }
        }

        &_arrow {
            position: absolute;
            top: 40%;
            left: -35px;
            z-index: 100;

            @media #{$xsmall} {
                top: -25px;
                left: 10px;
                transform: rotate(90deg);
            }
        }
    }

    &_bottom {
        display: var(--path-bottom-display, flex);
        width: 100%;
        justify-content: flex-end;
        position: relative;

        &_title {
            position: absolute;
            top: 10px;
            right: 33.33%;
            transform: translateX(50%);
            font-size: 17px;
            font-weight: font-weight(regular);
            color: $white;
        }

        &_area {
            flex: 1 1 33.3333%;
            max-width: 33.3333%;
            position: relative;
            padding-top: 60px;

            > i {
                position: absolute;
                left: calc(50% - 25px);
                top: 0;
                display: block;
                color: $light_blue;
                font-size: 50px;
                transform: rotate(180deg);
            }

            > p {
                width: 100%;
                text-align: center;
                color: $white;
                font-size: 16px;
                font-weight: font-weight(bold);
                padding: 0 10px;
            }
        }
    }

    &_detail {
        position: absolute;
        z-index: 5;
        pointer-events: none;

        @media #{$xsmall} {
            --detail-display: none;
        }

        &:nth-child(2) {
            display: var(--detail-display, block);
            top: 20px;
            left: 0;
            height: 20px;
        }

        &:nth-child(3) {
            width: 20px;
            left: 30px;
            bottom: 30px;

            @media #{$xsmall} {
                top: 20px;
                left: 40px;
                transform: rotate(90deg);
            }
        }

        &:nth-child(4) {
            width: 60px;
            top: -20px;
            right: 30px;

            @media #{$xsmall} {
                bottom: 0;
                top: inherit;
            }
        }

        &:nth-child(5) {
            display: var(--detail-display, block);
            height: 20px;
            right: 0;
            top: 40px;
        }
        &:nth-child(6) {
            display: var(--detail-display, block);
            width: 10px;
            right: 10px;
            bottom: 10px;
        }
        &:nth-child(7) {
            left: 30px;
            bottom: 15px;

            @media #{$xsmall} {
                bottom: inherit;
                top: 20%;
                left: -120px;
                transform: rotate(90deg);
            }
        }
    }
}

.needHelp {
    width: 100%;
    min-height: 50vh;
    padding: 80px 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_content {
        --help-skew: -23deg;
        width: 100%;
        max-width: 550px;
        min-width: 300px;
        padding-left: 100px;
        position: relative;

        @media #{$xsmall} {
            padding-left: 0;
            min-width: auto;
            --help-details-display: none;
            --help-details-mobile-display: block;
        }
    }

    &_box {
        height: 142px;
        border: solid 2px $dark_blue;
        border-radius: 0 4px 4px 0;
        padding: 40px 45px 40px 20px;
        border-left: none;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 10;

        @media #{$xsmall} {
            width: 100%;
            height: auto;
            border-radius: 0 0 4px 4px;
            border-left: solid 2px $dark_blue;
            border-top: none;
            flex-direction: column;
            align-items: flex-start;
            padding: 70px 40px 40px 40px;
            --help-box-p: 0 0 20px 0;
            --help-box-skewbox-display: none;
        }

        &::before {
            display: var(--help-box-skewbox-display, block);
            content: "";
            position: absolute;
            top: -2px;
            left: -30px;
            z-index: -1;
            width: 60px;
            height: calc(100% + 4px);
            background-color: $white;
            border-radius: 4px 0 0 4px;
            border: solid 2px $dark_blue;
            border-right: none;
            transform: skew(var(--help-skew));
        }

        &_texts {
            padding: var(--help-box-p, 0 20px 0 0);
            margin-right: auto;

            > h2 {
                font-size: 32px;
                color: $dark_blue;
                text-transform: uppercase;
            }

            > p {
                font-size: 18px;
                color: $dark;
            }
        }

        > button {
            height: 45px;
            min-height: 45px;
            color: $dark_blue;
            background-color: $white;
            border: solid 2px $dark_blue;
            border-radius: $border-btn-radius;
            padding: 2px 20px;
            font-size: 18px;
            font-weight: font-weight(bold);
            transition: color 0.3s, background-color 0.3s;
            cursor: pointer;

            &:hover {
                background-color: $dark_blue;
                color: $white;
            }
        }
    }

    &_details {
        display: var(--help-details-display, block);
        position: absolute;
        left: 40px;
        top: -20%;
        width: 70px;
        height: 140%;
        background-color: $dark_blue;
        transform: skew(var(--help-skew));

        &::before {
            content: "";
            width: 25px;
            height: 30%;
            position: absolute;
            top: 40%;
            left: -40px;
            background-color: $light_blue;
        }

        &_mobile {
            position: absolute;
            left: -20px;
            top: -20px;
            display: var(--help-details-mobile-display, none);
            width: calc(60vw - 20px);
            height: 30px;
            background-color: $dark_blue;
            transform: skewY(-7deg);
            z-index: 50;

            &::before {
                content: "";
                position: absolute;
                top: -30px;
                left: 100%;
                width: calc(40vw - 20px);
                height: 30px;
                background-color: $green;
            }

            &::after {
                content: "";
                position: absolute;
                top: 30px;
                left: calc(40vw - 20px);
                width: calc(60vw - 20px);
                height: 30px;
                background-color: $light_blue;
            }
        }
    }
}
