@import "../../../stylesheets/utilities";

.layout {
    width: 100%;
    height: 100%;
    min-height: 700px;
    max-width: 1000px;
    padding: 60px 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media #{$xsmall} {
        --show-details: none;
    }

    &_bg {
        &_top,
        &_bottom {
            max-width: 160px;
            position: absolute;
            z-index: 1;

            @media print {
                display: none;
            }
        }

        &_top {
            top: 20%;
            right: 0;
            display: var(--show-details, block);
        }

        &_bottom {
            left: 0;
            bottom: 0;
        }
    }
}
