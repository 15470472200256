.ats_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ats_main {
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
