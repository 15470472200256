.cardNumberWrapper {
    position: relative;

    > i {
        position: absolute;
        left: inherit !important;
        right: 6px;
        top: 33px !important;
        font-size: 30px !important;
    }

    .cardNumber {
        width: 100%;
        padding: 5px 45px 5px 10px;
    }
}
