@import "../../../stylesheets/utilities";

.footer {
    position: relative;
    width: 100%;
    margin-top: auto;
    background-color: var(--footer-bg);
    color: $white;

    &_top {
        width: 100%;
        padding: 30px 0;

        &_content {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: center;

            @media #{$xsmall} {
                flex-direction: column;
                align-items: center;
                --content-area-flex: 0 0 auto;
                --content-area-width: 100%;
                --content-area-maxw: 400px;
            }
        }
        &_area {
            flex: var(--content-area-flex, 1 1 195px);
            width: var(--content-area-width);
            max-width: var(--content-area-maxw, max-content);
            padding: 30px var(--padding);
            font-size: 14px;

            &:nth-child(1) {
                max-width: 250px;
                --padding: 10px;
            }

            > a {
                display: block;
                width: 100%;
                color: $white;
                padding: 5px 0;

                img {
                    max-width: 250px;
                }
            }
        }
    }

    &_title {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: font-weight(bold);
    }

    &_bottom {
        width: 100%;
        padding: 15px;
        background-color: $blue2;
        font-size: 14px;
        text-align: center;
        border-top: solid 10px $white;
    }
}

.cookies {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: z-index(header)+100;
    width: 100%;
    background-color: $dark;
    padding: 30px;
    transform: translateY(var(--cookies-y, 100%));
    transition: transform 0.4s ease-in-out;

    &.active {
        --cookies-y: 0;
    }

    &_content {
        width: 100%;
        max-width: $maxWidth;
        margin: 0 auto;
        color: $white;
        font-size: 14px;
        font-weight: font-weight(medium);
        line-height: 20px;
    }

    &_title {
        font-size: 20px;
        font-weight: font-weight(bold);
        margin-bottom: 15px;
    }

    &_buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
    }

    &_btn {
        border-radius: 4px;
        background-color: $white;
        color: $dark;
        font-size: 16px;
        font-weight: font-weight(bold);
        padding: 7px 10px;
    }
}

.goto_top {
    position: absolute;
    top: 0;
    right: var(--padding);
    width: var(--gotop-size, 65px);
    height: var(--gotop-size, 65px);
    background-color: $green;
    color: $white;
    border: none;
    font-size: var(--gotop-font, 40px);
    text-align: center;
    padding-top: 7px;
    transition: background-color 0.3s;
    transform: translateY(-40%);

    @media #{$xsmall} {
        --gotop-size: 35px;
        --gotop-font: 20px;
    }

    &:hover {
        --gotop-state: running;
        background-color: darken($green, 5%);
    }

    i {
        display: block;
        animation: gotop infinite alternate 0.4s;
        animation-play-state: var(--gotop-state, paused);
    }
}

@keyframes gotop {
    to{
        transform: translateY(-5px);
    }
}