@import "../../../stylesheets/utilities";

.aside {
    width: auto;
    height: 100%;
    padding: 20px;

    @media (max-width: 700px) {
        --aside-link-bg: #{$dark_blue};
        --aside-link-color: #{$white};
        --aside-box-border: none;
        --aside-link-border: none;
        --aside-btn-display: block;
        background-color: $dark_blue;
        position: sticky;
        top: 0;
        z-index: 400;
    }

    &_box {
        position: sticky;
        z-index: 300;
        top: 150px;
        width: 100%;
        background-color: $white;
        border: var(--aside-box-border, solid 2px $dark_blue);
        border-radius: 4px;
        overflow: hidden;

        > i {
            display: var(--aside-btn-display, none);
            position: absolute;
            right: 0;
            top: -7px;
            background-color: $dark_blue;
            color: $white;
            font-size: 30px;
            padding: 10px;
            cursor: pointer;
            transform: rotate(var(--aside-i-rotate, 180deg));
            transition: transform 0.3s;
        }

        > a {
            display: block;
            padding: var(--aside-link-pad, 25px);
            font-size: 14px;
            font-weight: var(--aside-link-weight, font-weight(regular));
            color: var(--aside-link-color, $dark_blue);
            background-color: var(--aside-link-bg, $white);
            border-left: solid 10px var(--aside-link-border, transparent);
            transition: background-color 0.3s, border-left 0.3s;

            @media (min-width: 700px) {
                &.active {
                    --aside-link-weight: #{font-weight(bold)};
                    --aside-link-bg: #{$light_sky_blue};
                    --aside-link-border: #{$dark_blue};
                }
                &:hover {
                    --aside-link-bg: #{$aqua};
                }
            }

            @media (max-width: 700px) {
                padding: var(--aside-link-pad, 0 40px 0 10px);
                max-height: var(--aside-link-mh, 0);
                overflow: hidden;

                &.active {
                    --aside-link-pad: 10px 40px 10px 10px;
                    --aside-link-mh: 80px;
                    --aside-link-weight: #{font-weight(bold)};
                }

                &:hover {
                    --aside-link-bg: #{$dark_blue};
                }
            }
        }

        @media (max-width: 700px) {
            border-radius: 0;

            &.active {
                --aside-link-mh: 80px !important;
                --aside-link-pad: 10px 40px 10px 10px !important;
                --aside-i-rotate: 0;
            }
        }
    }
}
