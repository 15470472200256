.calendar_wrapper {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0px;
    min-height: 130px;
    justify-content: space-between;
    border-left: 12px solid var(--mainColor);
    width: 100%;

    .calendar_header {
        display: flex;
        justify-content: space-between;
        color: var(--mainColor);
        padding: 0px 40px;

        .title_container {
            width: 80%;
            display: flex;
            gap: 10px;
            h2 {
                text-transform: capitalize;
            }
        }
    }

    .enroll_btn {
        padding: 8px 16px;
        border-radius: 4px;
        background-color: var(--mainColor);
        color: white;
        border: 1px solid rgba(0, 0, 0, 0);
        cursor: pointer;
        height: fit-content;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            background-color: white;
            color: var(--mainColor);
            border: 1px solid var(--mainColor);
        }
        &.mobile_btn {
            display: none;
        }
    }
    .calendar_row {
        padding: 0px 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--mainColor);

        .calendar_container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .place_container {
                display: flex;
                flex-direction: column;
                span {
                    color: #394851;
                    font-family: Noto Sans;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .dayDuration {
                    color: var(--mainColor);
                    font-family: Noto Sans;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .date_tile_container {
                display: flex;
                flex-direction: row;
                gap: 20px;
                align-items: center;
                .dash_divider {
                    width: 16px;
                    height: 4px;
                    border-radius: 2px;
                    background: var(--mainColor);
                }
            }
        }
        &:last-of-type {
            border-bottom: none;
            padding-bottom: 0px;
        }
    }
}

.defaultTitle {
    padding: 0px 40px;
}

@media (max-width: 880px) {
    .calendar_wrapper {
        border-left: none;
        border-top: 12px solid var(--mainColor);
        padding-bottom: 0px;
        .calendar_header {
            flex-direction: column;
            gap: 20px;
        }

        .calendar_row {
            .calendar_container {
                flex-direction: column;
                gap: 30px;
                .place_container span {
                    font-size: 16px;
                    &.dayDuration {
                        font-size: 16px;
                    }
                }
                .date_tile_container {
                    justify-content: center;
                }
            }
        }
        .enroll_btn {
            margin: 10px;
            padding: 12px 16px;
            &.desktop_btn {
                display: none;
            }
            &.mobile_btn {
                display: block;
            }
        }
    }

    .defaultTitle {
        display: flex;
        justify-content: center;
        padding: 15px 40px 40px;
    }
}

@media (max-width: 650px) {
    .calendar_wrapper {
        .calendar_header {
            padding: 15px 30px;
            .title_container {
                width: 100%;
                justify-content: center;
                h2 {
                    font-size: 16px;
                }
            }
        }
        .calendar_row {
            display: flex;
            justify-content: center;
            .calendar_container {
                .date_tile_container {
                    flex-direction: column;
                }
                .dash_divider {
                    display: none;
                }
            }
        }
    }

    .defaultTitle {
        p {
            text-align: center;
        }
    }
}
