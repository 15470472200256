@import "../../../stylesheets/utilities";

.layout {
    width: 100%;
    max-width: 1000px;
    padding: 60px 30px;
    margin: 0 auto;

    &_bg {
        
        &_top,
        &_bottom {
            display: var(--show-details, block);
            position: absolute;
            z-index: 1;
        
            @media print {
                display: none;
            }
        }

        &_top {
            top: 20%;
            right: 0;
        }

        &_bottom {
            left: 0;
            bottom: 0;
        }
    }
}

.card {
    position: relative;
    z-index: 100;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px 40px 20px;
    margin: 0 auto;
    text-align: center;
    border: solid 1px $green;
    border-radius: 4px;
    background-color: $light_green;

    @media print {
        display: none;
    }

    > i {
        font-size: 40px;
        color: $white;
        margin-top: -80px;
        background-color: $green;
        border-radius: 100%;
        padding: 10px;
    }

    &_title {
        font-size: 20px;
        font-weight: font-weight(bold);
        text-transform: uppercase;
        margin: 20px 0;
    }

    p {
        font-size: 16px;
        max-width: 360px;
    }
}

.summary {
    position: relative;
    z-index: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 1px $dark_blue;
    background-color: $white;
    margin: 30px 0;
    border-radius: 4px;

    &_heading {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 25px;
        border-radius: 4px 4px 0 0;
        color: $white;
        background-color: $dark_blue;

        @media print {
            --display: none;
            --img-filter: invert(1);
            color: $dark;
            background-color: $blue;
        }

        > img {
            filter: var(--img-filter, none);
        }

        &_id {
            padding: 5px 20px;
            margin-left: 20px;
            border-left: solid 1px $white;
        }

        button {
            display: var(--display, block);
            background-color: transparent;
            border: solid 1px $white;
            margin-left: auto;
        }
    }

    &_body {
        width: 100%;
        max-width: 700px;
        padding: 20px;
        margin: 0 auto;

        &_info {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            padding: 30px 0;

            @media screen and (max-width: 600px) {
                grid-template-columns: 1fr;
                --line-left: none;
            }

            > div {
                padding: 15px;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:nth-child(2) {
                    border-left: var(--line-left, solid 1px $dark_blue);
                }

                p {
                    margin-bottom: 15px;
                }
                strong {   
                    color: $blue;
                    font-weight: font-weight(bold);
                    text-transform: uppercase;
                }
                
                .space_between {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                .total {
                    font-size: 18px;
                    border-top: solid 1px $dark;
                    padding: 10px 0;

                    &_box {
                        width: 100%;
                        max-width: 200px;
                        margin: 0 auto;
                    }
                }
            }
        }

        &_list {
            width: 100%;
            border-radius: 4px;
            background-color: $aqua;
            padding: 30px;

            &_content {
                width: 100%;
                max-width: 550px;
                margin: 0 auto;
                overflow: auto;
            }
        }

        &_date {
            width: 100%;
            font-size: 14px;
            opacity: 0.5;
            text-align: right;
            padding: 10px 0;
        }
    }

    > a {
        margin-bottom: -15px;

        @media print {
            display: none;
        }
    }
}