.page_wrapper {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    .page_container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        max-width: 1000px;
        padding: 20px;
        .select_container {
            max-width: 430px;
            margin-right: auto;
        }
    }
}

.footer_detail_container {
    width: 100%;
    display: flex;
    margin-top: auto;
    max-width: 1500px;
    height: fit-content;
    padding-top: 30px;
    .course_detail_footer {
        width: 20%;
    }
}

.loadingContainer {
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 840px) {
    .page_wrapper {
        padding: 50px 20px 0;
    }
    .footer_detail_container {
        .course_detail_footer {
            display: none;
        }
    }
}

@media (max-width: 380px) {
    .page_wrapper {
        .select_container {
            width: 100%;
        }
    }
}
