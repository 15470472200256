@import "../../../stylesheets/utilities";


.list {
    width: 100%;
    font-size: 14px;
    color: $gray;

    thead {
        font-weight: font-weight(bold);
        text-transform: uppercase;
    }

    td, th {
        padding: 10px;
    }

    tr + tr td {
        border-top: solid 1px lighten($gray, 50%);
    }
}

.approved {
    color: var(--color-green);
}

.pending {
    color: var(--color-orange);
}

.rejected {
    color: var(--color-red);
}

.refunded {
    color: var(--color-red);
}
.dot {
    &::before {
        content: "\2022";
        margin-right: 5px;
    }
}

.iconPayment {
    font-size: 19px;
    vertical-align: text-top;
    margin-right: 3px;
    &.paid {
        color: var(--color-green);
        font-size: 19px;
    }

    &.pending {
        color: var(--color-orange);
        font-size: 19px;
    }

    &.refunded {
        color: var(--color-red);
        font-size: 19px;
    }
}
