@import "../../stylesheets/utilities";

.layout {
    flex: 1 1 auto;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    margin: 0 auto;
    display: grid;
    gap: var(--layout-gap, 40px);
    grid-template-columns: var(--aside-w, 300px) 1fr;

    &_content {
        width: 100%;
        padding: 20px;
    }

    @media (max-width: 1000px) {
        --layout-gap: 0;
        --aside-w: 250px;
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        padding: 0;
        --bg-detail-show: none;
    }

    > img {
        display: var(--bg-detail-show, block);
        position: absolute;
        max-width: 200px;
        max-height: 300px;
    }

    &_detail_right {
        top: 400px;
        right: 0;
    }

    &_detail_footer {
        bottom: 0;
        left: 0;
    }

    .spinnerWrapper {
        grid-column: 1/-1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.article {
    width: 100%;
    position: relative;
    z-index: 300;
    background-color: $white;

    @media (max-width: 1000px) {
        --footer-gap: 0;
    }

    &_heading {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 40px 0;

        > img {
            height: clamp(40px, 8vw, 70px);
            margin-right: clamp(10px, 6vw, 30px);
        }

        > h2 {
            font-size: clamp(20px, 5vw, 36px);
            font-weight: font-weight(bold);
        }
    }

    &_body {
        width: 100%;

        p {
            font-size: 16px;
            padding: 10px 0;
            margin-bottom: 10px;
            line-height: 22px;

            a:hover {
                text-decoration: underline;
            }

            a,
            a:active,
            a:visited {
                color: $blue;
            }
        }

        &_download {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: $blue;
            font-weight: font-weight(bold);

            > i {
                font-size: 30px;
                margin-right: 5px;
            }
        }
    }

    &_footer {
        width: 100%;
        max-width: 500px;
        padding: 30px 0;

        @media (max-width: 800px) and (min-width: 700px) {
            --column-area: span 2;
        }

        @media #{$xsmall} {
            display: flex;
            flex-direction: column;
            gap: 0;
            --goto-pY: 20px 0;
        }

        &_set {
            grid-column: var(--column-area);
            margin-bottom: 20px;
        }

        &_location {
            width: 100%;
            font-size: 20px;
            font-weight: font-weight(bold);
            position: relative;
            z-index: 1;
            
            &.active {
                --location-mh: 163px;
                --location-overflow: auto;
                --location-rotate: 180deg;
            }

            &.required {
                position: relative;
                --required-opacity: 1;
                --required-display: block;  
                
                @media screen and (max-width:1055px) {
                    --required-width: auto;
                }

                @media (max-width: 880px) {
                    --required-width: max-content;
                    --required-top: 80%;
                    --required-y: 0;
                    --required-left: auto;
                    --required-right: 20px;
                    --required-detail-top: -3px;
                    --required-detail-left: 85%;
                    --required-rotate: 90deg;
                }
            }
            >.tooltip {
                display: var(--required-display, none);
                width: var(--required-width, max-content);
                position: absolute;
                top: var(--required-top, 50%);
                left: var(--required-left, calc(100% + 10px));
                right: var(--required-right, inherit);
                font-size: 12px;
                font-weight: font-weight(regular);
                background-color: $sky_blue;
                border-radius: 5px;
                padding: 10px;
                transform: translateY(var(--required-y, -50%));
                pointer-events: none;
                opacity: var(--required-opacity, 0);
                transition: opacity 0.3s;

                &::after {
                    content: "";
                    width: 12px;
                    height: 10px;
                    position: absolute;
                    left: var(--required-detail-left, -10px);
                    top: var(--required-detail-top, 50%);
                    clip-path: polygon(100% 0, 0 50%, 100% 100%);
                    background-color: $sky_blue;
                    transform: translateY(-50%) rotate(var(--required-rotate));
                }
            }

            &_top {
                width: 100%;
                padding: 15px 20px;
                background-color: $dark;
                color: $white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                i {
                    font-size: 30px;
                    transform: rotate(var(--location-rotate, 0));
                    transition: transform 0.3s ease-in-out;
                }
            }

            &_list {
                width: 100%;
                max-height: var(--location-mh, 0);
                overflow: var(--location-overflow, hidden);
                position: absolute;
                background-color: $white;
                border: solid 1px $dark;
                padding: 0;
                transition: max-height 0.3s;

                li {
                    padding: 15px 20px;
                    transition: background-color 0.3s, color 0.3s;
                    cursor: pointer;

                    + li {
                        border-top: solid 1px $dark;
                    }

                    &:hover {
                        color: $white;
                        background-color: $gray;
                    }
                }
            }
        }

        &_seats {
            width: 100%;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: solid 1px $dark;

            @media (max-width: 400px) {
                --seats-max-w: 100%;
                --seats-p: 10px 0;
                flex-direction: column;
            }

            > p {
                max-width: var(--seats-max-w, max-content);
                padding: var(--seats-p);
                font-size: 20px;
                font-weight: font-weight(bold);
                text-transform: uppercase;
            }

            &_buttons {
                display: flex;
                border: solid 2px $dark;

                button {
                    width: 45px;
                    height: 45px;
                    border: none;
                    background-color: $white;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 25px;
                    transition: background-color 0.3s, color 0.3s;

                    &:hover {
                        background-color: $dark;
                        color: $white;
                    }
                }

                input {
                    max-width: 70px;
                    height: 45px;
                    margin: 0;
                    border: solid 2px $dark;
                    border-top: none;
                    border-bottom: none;
                    text-align: center;
                    outline: none;
                }
            }
        }

        &_goto {
            padding: var(--goto-pY);

            &_btn {
                width: 100%;
                display: flex;
                align-items: center;
                border: none;
                padding: 13px 20px;
                text-align: left;
                font-size: 20px;
                font-weight: font-weight(bold);
                color: $white;
                background-color: $blue;
                clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0% 100%);
                transition: background-color 0.3s;

                &:hover {
                    background-color: $green;
                    --goto-i-opacity: 1;
                }

                &.disabled {
                    opacity: 0.6;
                    filter: grayscale(1);
                    pointer-events: none;
                }

                i {
                    font-size: 25px;
                    margin-right: 10px;
                }

                .arrow {
                    opacity: var(--goto-i-opacity, 0);
                    transform: rotate(90deg) translateY(0);
                    margin-left: auto;
                    transition: opacity 0.3s;
                    animation: goto infinite 0.5s alternate;
                }
            }
        }
    }
}

@keyframes goto {
    to {
        transform: rotate(90deg) translateY(-10px);
    }
}
