.select_wrapper {
    width: 100%;
    position: relative;
    padding: 0;
    transition: max-height 0.3s;
    button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 10px;
        border-radius: 4px;
        border: 1px solid #003c74;
        background: #fff;
        span {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            color: #394851;
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        img {
            &.chevron_closed {
                transform: rotate(0deg);
                transition: transform 0.2s ease-in-out;
            }
            &.chevron_open {
                transform: rotate(180deg);
                transition: transform 0.2s ease-in-out;
            }
        }
    }
    .select_list {
        background-color: #ffffff;
        position: absolute;
        border: 1px solid #004a8f;
        border-radius: 4px;
        padding: 0px;
        width: 100%;
        font-size: 16px;
        margin-top: 10px;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
        visibility: none;
        pointer-events: none;
        &.expanded {
            opacity: 1;
            transform: translateY(0px);
            pointer-events: all;
        }

        li {
            padding: 15px 10px;
            transition: background-color 0.3s, color 0.3s;
            cursor: pointer;
            border-bottom: 1px solid #004a8f;
            font-size: 16px;
            &.active {
                background-color: #e0e0e0;
                &:first-of-type {
                    border-radius: 4px 4px 0 0;
                }
                &:last-of-type {
                    border-radius: 0 0 4px 4px;
                }
            }
            &:hover {
                color: #ffffff;
                background: #004a8f;
            }
            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}
