@import "../../../../stylesheets/utilities";

.accordion {
    &_item {
        width: 100%;
        border: 1px solid rgba(0, 74, 143, 0.3);
        border-radius: 4px;
        transition: box-shadow 0.3s;
        position: relative;

        + li {
            margin-top: 15px;
        }

        &.active {
            --accordion-h: 600px;
            --accordion-title: 700;
            --accordion-deg: 180deg;
            --accordion-pt: 20px;
            --accordion-pb: 40px;
            box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
        }

        &:hover {
            box-shadow: 0px 4px 8px rgba(0, 74, 143, 0.1);
        }

        > i {
            position: absolute;
            top: 0;
            right: 0;
            color: $blue;
            cursor: pointer;
        }
    }

    &_heading {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $blue;
        font-size: 14px;
        transition: background-color 0.3s;
        cursor: pointer;

        &:hover {
            background-color: var(--color-sky-blue);
        }

        span {
            margin-right: auto;
            font-weight: var(--accordion-title, 500);
            overflow-wrap: anywhere;
        }

        i {
            margin: 0 5px;
            font-size: 20px;
            transition: transform 0.3s;
            color: var(--icon-color);

            &:nth-last-child(1) {
                transform: rotate(var(--accordion-deg, 0deg));
            }
        }
    }

    &_content {
        width: 100%;
        max-height: var(--accordion-h, 0);
        overflow: hidden;
        padding: var(--accordion-pt, 0) 20px var(--accordion-pb, 0) 20px;
        transition: max-height 0.3s ease, padding-bottom 0.3s;
    }
}
