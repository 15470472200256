@import "../../../stylesheets/utilities";

.hero {
    width: 100%;
    min-height: 30vh;
    background-color: $dark;
    position: relative;

    @media #{$xsmall} {
        --hero-image-display: none;
        --container-pad: 80px var(--padding);
        --bg-display: none;
        --bg-mobile-display: block;
        --content-text-align: center;
        --text-high-display: block;
        --text-high-size: 21px;
        --text-high-weight: #{font-weight(bold)};
    }

    &_container {
        width: 100%;
        max-width: $maxWidth;
        height: 100%;
        padding: var(--container-pad, 150px 40px);
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    &_content {
        width: 100%;
        max-width: 500px;
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: var(--content-text-align, flex-start);

        &_text {
            line-height: 1.6;
            margin-bottom: 30px;
            text-align: var(--content-text-align, left);

            &_high {
                display: var(--text-high-display, auto);
                font-size: var(--text-high-size, auto);
                font-weight: var(--text-high-weight, auto);
                margin-bottom: 35px;
            }
        }

        > img {
            display: var(--hero-image-display, block);
            height: 50px;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            font-weight: font-weight(light);
            color: $white;
            padding-bottom: 20px;
        }
    }

    &_btn {
        padding: 13px 50px;
        font-size: 18px;
        font-weight: font-weight(bold);
        background-color: $white;
        color: $dark;
        border-radius: $border-btn-radius;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            color: $white;
            background-color: $blue;
        }
    }

    &_bg {
        display: var(--bg-display, block);
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        &_mobile {
            display: var(--bg-mobile-display, none);
            width: 100%;
        }
    }
}
