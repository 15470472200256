.date_tile_wrapper {
    display: flex;
    background: rgba(42, 144, 208, 0.05);
    border: 1px solid var(--mainColor);
    border-radius: 8px;
    align-items: center;
    padding: 10px 30px;
    width: 240px;
    justify-content: space-between;
    max-height: 80px;
    height: 80px;

    .title_container,
    .day_container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .title {
            color: var(--mainColor);
            font-family: Noto Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .time {
            color: #394851;
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .month {
            color: var(--mainColor);
            text-align: center;
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
        }
        .day {
            color: #394851;
            text-align: center;
            font-family: Noto Sans;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .dash_divider {
        height: 100%;
        border: 0.25px solid var(--mainColor);
    }
}
