.tileContainer {
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    position: relative;
    min-width: 340px;
    width: 340px;
    height: 290px;
    border: 1px solid var(--mainColor);
    border-radius: 10px;
    border-top: 25px solid var(--mainColor);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        top: -20px;
    }
    .textContainer {
        margin-top: 30px;
        height: 240px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        color: #394851;
        .title {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 5px;

            h3 {
                color: var(--mainColor);
                font-size: 26px;
            }
            h4 {
                font-size: 20px;
            }
        }
        .date {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            .middleLine {
                width: 160px;
                border-top: 1px solid var(--mainColor);
            }
            .courseDay {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                .month {
                    font-size: 16px;
                    color: var(--mainColor);
                    text-transform: uppercase;
                    font-weight: 500;
                }
                .day {
                    font-size: 32px;
                    font-weight: 700;
                }
                .hour {
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
            .dayLeft {
                font-size: 16px;
                text-transform: capitalize;
            }
        }
    }
    .button {
        padding: 10px 35px;
        border: 1px solid var(--mainColor);
        position: absolute;
        bottom: -25px;
        border-radius: 10px;
        background: white;
        color: var(--mainColor);
        font-family: Noto Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        text-transform: capitalize;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            background: var(--mainColor);
            color: white;
        }
    }
}
.defaultTile {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 45px 20px;

    h3 {
        color: var(--mainColor);
        font-size: 26px;
    }
    p {
        font-size: 21px;
    }
}
