@import "../../../stylesheets/utilities";

.heroMini {
    width: 100%;
    background-color: $dark;
    overflow: hidden;
    position: relative;

    &_content {
        width: 100%;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 40px var(--padding);
    }

    &_title {
        font-size: clamp(16px, 5vw, 48px);
        color: $white;
    }

    > img {
        max-width: 60%;
        position: absolute;
        right: 0;
        top: 30px;
    }
}
