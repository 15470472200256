.cvvWrapper {
    position: relative;

    > i {
        position: absolute;
        left: inherit !important;
        right: 5px !important;
        top: 32px !important;
        font-size: 30px !important;
    }
    .cvv {
        width: 100%;
        padding: 5px 10px 5px 40px;
        text-align: right;
    }
}
