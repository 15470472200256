//SIZES
$maxWidth: 1200px;

$border-btn-radius: 3px;

//COLORS

$white: #ffffff;

$aqua: #F2F6F9;

$blue: #0177bf;
$blue2: #2A90D0;
$dark_blue: #004a8f;
$light_blue: #2a90d0;

$light_sky_blue: #e6edf4;
$sky_blue: #5fc4e9;
$text_blue: #144361;

$green: #039B54;
$light_green: #DAF6E9;

$light_gray: #efefef;
$gray: #394851;
$dark: #071a25;

$red: #CF272A;
$light_red: #E06658;
$aqua_red: #F7D9D5;

:root {
    --header-bg: #{$blue};
    --submenu-bg: #{$gray};
    --footer-bg: #{$gray};
}
