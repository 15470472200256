@import "../../../stylesheets/utilities";

.customSelect {
    --item-padding: 7px 20px 7px 10px;
    position: relative;

    >button {
        width: 100%;
        height: 40px;
        padding: var(--item-padding, 7px 10px);
        background-color: $light_sky_blue;
        border: none;
        border-radius: 4px;
        text-align: left;
        position: relative;

        &:focus {
            outline: solid 2px $dark_blue;
        }

        &.toggled {
            --rotate_arrow: -180deg;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
            filter: grayscale(0.5);
        }

        >span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        >i {
            position: absolute;
            top: 10px;
            right: 3px;
            font-size: 20px;
            transition: transform 0.3s;
            transform: rotate(var(--rotate_arrow, 0));
        }
    }

    &_Options {
        position: absolute;
        z-index: 50;
        width: 100%;
        max-height: 160px;
        min-width: min-content;
        background-color: $white;
        padding: 0;
        border: solid 1px $dark_blue;
        border-radius: 4px;
        box-shadow: 0px 3px 5px rgba($color: #000000, $alpha: 0.1);
        overflow: auto;



        li {
            +li {
                border-top: solid 1px $dark_blue;
            }

            &:hover {
                background-color: var(--color-lighter-sky-blue);
            }

            button {
                width: 100%;
                height: 40px;
                padding: 10px 15px;
                text-align: left;
                display: block;
                overflow: hidden;
                font-size: 14px;
                font-weight: font-weight(regular);
                color: $dark_blue;
                background-color: $white;
                border: none;
                transition: background-color 0.3s, color 0.3s;

                &:hover {
                    color: $white;
                    background-color: $dark_blue;
                }

                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                    filter: grayscale(0.5);
                }
            }
        }

        &_row {
            width: 100%;
        }

    }
}