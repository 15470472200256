@import "../../stylesheets/utilities";

.layout {
    width: 100%;
    max-width: 1200px;
    padding: 100px 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    @media #{$xsmall} {
        flex-direction: column;
        align-items: center;
    }

    > div {
        flex: 1 1 auto;
        z-index: 50;
        padding: var(--padding);
        background-color: $white;
    }

    &_info {
        max-width: 400px;
        position: relative;

        &_title {
            font-size: 64px;
            font-weight: font-weight(regular);
            margin-bottom: 20px;
        }

        > p {
            font-size: 18px;
            margin-bottom: 20px;
        }

        > img {
            max-width: calc(100% - 130px);
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &_form {
        max-width: 500px;
    }

    > img {
        position: absolute;
        left: 0;
        z-index: 5;
        width: 250px;

        @media #{$xsmall} {
            display: none;
        }
    }
}

.item {
    width: 100%;
    position: relative;
    padding: 10px 0 30px;

    input, textarea, select {
        width: 100%;
        min-height: 45px;
        padding: 10px;
        border-radius: 4px;
        border: solid 1px var(--item-border, $dark_blue);
        outline: none;

        &:placeholder-shown + label{
            --item-label-y: 9px;
            --item-label-x: 10px;
            --item-label-size: 16px;
            --item-label-weight: 400;
            color: $dark;
        }
        &:focus + label {
            --item-label-y: -15px;
            --item-label-x: 7px;
            --item-label-size: 12px;
            --item-label-weight: 700;
            color: $blue;
        }
        &:focus {
            --item-border: #{$blue};
        }
        &:invalid {
            --item-border: #{$red};

            + label {
                color: $red;
            }
        }
    }

    label {
        position: absolute;
        left: 0;
        text-align: left;
        padding: 5px;
        color: $blue;
        background-color: $white;
        font-size: var(--item-label-size, 12px);
        font-weight: var(--item-label-weight, 700);
        transform: 
            translate(var(--item-label-x, 7px), var(--item-label-y, -15px)) 
        ;
        transition: transform 0.4s, font-size 0.4s;
    }

    &_check {
        width: 100%;

        label {
            display: block;
            width: 100%;
            cursor: pointer;
            position: relative;

            input {
                width: 20px;
                height: 20px;
                position: absolute;
            }

            span {
                width: 100%;
                font-size: 16px;
                padding: 10px 10px 10px 30px;
            }

            p {
                font-size: 9px;
                padding: 10px 0;
                line-height: 12px;

                a,
                a:active,
                a:focus {
                    color: $blue;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }


    &_submit {
        width: 100%;
        border: none;
        color: $white;
        padding: 15px;
        margin: 20px 0;
        border-radius: 4px;
        background-color: $green;
        transition: background-color 0.3s;

        &:hover {
            background-color: darken($green, 10);
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.5;
            filter: grayscale(1);
        }

        &.loading {
            position: relative;
            pointer-events: none;
            overflow: hidden;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                cursor: progress;
                animation: btnload 1.8s forwards infinite linear;
                background: linear-gradient(to right, #79797900 8%, #ffffff94 38%, #79797900 54%);
                background-size: 1000px 640px;
            }
        }

        @keyframes btnload {
            0% {
                background-position: -468px 0;
            }

            100% {
                background-position: 468px 0;
            }
        }

    }
}