@import "../../../stylesheets/utilities";

.ats_header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    z-index: z-index(header);
    background-color: var(--header-bg);

    @media #{$xsmall} {
        --menu-display: flex;
        --identity-pad: 25px var(--padding);
    }

    &.active {
        --submenu-mobile-mh: 500px;
        --submenu-mobile-py: 20px;
        --menu-icon-t: 0;
        --close-icon-t: 1;
    }

    &_content {
        width: 100%;
        height: 100%;
        max-width: $maxWidth;
        margin: 0 auto;
        display: flex;
    }

    &_identity {
        padding: var(--identity-pad, 30px var(--padding));

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &_menu {
        //rest styles
        width: 70px;
        height: 100%;
        display: var(--menu-display, none);
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 30px;
        margin-left: auto;

        > i {
            position: absolute;
            font-size: 30px;
            color: $white;
            transition: transform 0.4s ease;

            &:nth-child(1) {
                transform: scale(var(--menu-icon-t, 1));
            }

            &:nth-child(2) {
                transform: scale(var(--close-icon-t, 0));
            }
        }
    }

    &_nav {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 35px;
        padding: 0 20px;
        @media #{$xsmall} {
            --nav-link-detail: none;
            position: fixed;
            z-index: z-index(header) + 50;
            top: 70px;
            background-color: var(--header-bg);
            flex-direction: column;
            align-items: flex-start;
            padding: var(--submenu-mobile-py, 0) 0;
            max-height: var(--submenu-mobile-mh, 0);
            overflow: hidden;
            transition: max-height 0.5s ease, padding 0.5s ease;
        }

        > a {
            height: 100%;
            font-size: 18px;
            font-weight: font-weight(bold);
            color: $white;
            display: flex;
            align-items: center;
            position: relative;

            &:hover {
                --nav-link-underline: 100%;
                --nav-link-arrow: 180deg;
            }

            &.showSubNav:hover + .ats_header_subnav {
                --submenu-mh: 30vh;
                --submenu-events: all;
                --submenu-opacity: 1;
            }

            span {
                position: relative;

                &::after {
                    display: var(--nav-link-detail, block);
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    width: 100%;
                    height: 2px;
                    background-color: $white;
                    transform-origin: left;
                    transform: scaleX(var(--nav-link-underline, 0));
                    transition: transform 0.3s ease;
                }
            }

            i {
                font-size: 25px;
                transform: rotate(var(--nav-link-arrow, 0deg));
                transition: transform 0.3s ease;
            }
        }
    }

    &_subnav {
        position: fixed;
        z-index: z-index(header) - 10;
        top: 100px;
        left: 0;
        width: 100%;
        height: auto;
        max-height: var(--submenu-mh, 0);
        background-color: var(--submenu-bg);
        padding: 0 40px;
        opacity: var(--submenu-opacity, 0);
        overflow: hidden;
        pointer-events: var(--submenu-events, none);
        transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
        display: flex;
        justify-content: space-around;

        @media #{$xsmall} {
            --submenu-img: none;
            --submenu-size: 14px;
            --submenu-weight: font-weight(medium);
            --submenu-p: 10px 30px;
            --submenu-details: block;
            --submenu-flex: 0 0 30px;

            position: relative;
            top: 0;
            flex-direction: column;
            background-color: transparent;
            padding: 0;
        }

        &:hover {
            --submenu-mh: 30vh;
            --submenu-events: all;
            --submenu-opacity: 1;
        }

        > a {
            flex: var(--submenu-flex, 0 1 260px);
            display: flex;
            align-items: center;
            padding: var(--submenu-p, 40px 10px);
            position: relative;

            + a::before {
                display: var(--submenu-details, none);
                content: "";
                position: absolute;
                top: 0;
                left: 30px;
                width: 80px;
                height: 1px;
                background-color: $white;
            }

            div {
                display: var(--submenu-img, block);
                flex: 0 0 55px;
                width: 55px;
                height: 55px;
                margin-right: 30px;
                border-radius: 50%;
                background-color: $white;
                padding: 13px;
            }

            span {
                font-size: var(--submenu-size, 16px);
                font-weight: var(--submenu-weight, font-weight(semibold));
                color: $white;
            }
        }
    }
}
