.errorMsg {
    position: absolute;
    width: 100%;
    top: 110%;
    left: 0;
    z-index: 1;
    color: var(--card-color);
    background-color: var(--card-bg);
    font-size: 11px;
    border: solid 1px var(--card-color);
    border-radius: var(--border-radius);
    padding: 10px;

    &::before {
        content: "";
        top: -7px;
        width: 10px;
        height: 7px;
        position: absolute;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background-color: var(--card-color);
    }

    > i {
        font-size: 25px;
        float: left;
        margin-right: 10px;
    }

    p {
        padding: 4px 0;
    }

    p + p {
        border-top: solid 1px var(--card-color);
    }
}
