@import "../../../../stylesheets/utilities";

//RADIO BUTTON
.radioButton {
    display: block;
    position: relative;
    cursor: pointer;

    > input {
        width: auto !important;
        padding: 0 !important;
        position: absolute;
        top: -7px;
        left: -5px;
        outline: none !important;

        &:focus {
            + button {
                outline: solid 2px $blue;
            }
        }

        &:checked + button {
            --radioButton-bg: #{$light_sky_blue};
            --radioButton-color: #{$blue};
            --radioButton-border: 2px;
        }
    }

    > button {
        min-width: max-content;
        width: 100%;
        height: auto;
        border: solid var(--radioButton-border, 1px) $blue;
        border-radius: $border-btn-radius;
        background-color: var(--radioButton-bg, $white);
        color: var(--radioButton-color, $blue);
        font-size: 16px;
        font-weight: font-weight(bold);
        padding: 10px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        > i {
            font-size: 22px;
            margin-right: 5px;
        }
    }

    + label {
        margin-left: 15px;
    }
}
