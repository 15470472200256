@import "../../../stylesheets/utilities";

.resume {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.summary {
    width: 100%;
    background-color: $light_gray;
    padding: 20px;
    border-radius: 4px;
    color: $gray;
    font-size: 14px;
    font-weight: font-weight(regular);

    &_columns {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    &_title {
        font-size: 18px;
        font-weight: font-weight(extra-bold);
        margin-bottom: 15px;
    }

    &_subtitle {
        font-size: 14px;
        font-weight: font-weight(bold);
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 10px;
    }
}

.totalprice {
    width: 100%;
    background-color: $dark_blue;
    color: $white;
    margin-top: 20px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    &.show {
        --expand-mh: 30vh;
        --expand-py: 20px;
        --price-iconr: 0;
    }

    &_top {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 20px;
    }

    &_text {
        font-size: 16px;
        font-weight: font-weight(bold);
    }

    &_btn {
        font-size: 14px;
        font-weight: font-weight(regular);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        background-color: transparent;
        border: none;
        color: $white;
        padding: 0;
        cursor: pointer;

        > i {
            display: block;
            margin-left: 5px;
            transform: rotate(var(--price-iconr, 180deg));
            transition: transform 0.3s ease;
        }
    }

    &_expand {
        width: 100%;
        max-height: var(--expand-mh, 0);
        list-style: none;
        margin: 0;
        padding: var(--expand-py, 0) 20px;
        background-color: #ffffff;
        color: #004a8f;
        border: solid 1px #004a8f;
        border-radius: 0 0 4px 4px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;

        li {
            padding: 10px 0;

            + li {
                border-top: solid 1px $dark_blue;
            }

            p {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.cancelPolicy {
    width: 100%;
    margin-top: 20px;
    border: solid 1px $dark_blue;
    border-radius: 4px;
    background-color: $light_sky_blue;
    color: $dark_blue;

    &_heading {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 15px 20px;

        > i {
            font-size: 25px;
        }

        &_title {
            font-size: 14px;
            font-weight: font-weight(bold);
            text-transform: uppercase;
            padding: 0 10px;
        }
    }

    &_content {
        width: 100%;
        margin: 0;
        padding: 10px 20px;

        li {
            font-size: 14px;
            padding: 10px 0;

            p {
                margin-bottom: 3px;
            }

            + li {
                border-top: solid thin $blue;
            }
        }
    }
}

.summaryWarning {
    width: 100%;
    margin-top: 20px;
    border: solid 1px #ff9a28;
    border-radius: 4px;
    background-color: #ffe5c9;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px 0;

    &_heading {
        flex-direction: column;
        width: 100%;
        display: flex;
        align-items: center;
        color: #ff9a28;

        > i {
            font-size: 30px;
        }

        &_title {
            font-size: 14px;
            padding: 0 10px;
        }
    }

    &_content {
        width: 100%;
        margin-bottom: 3px;
        padding: 10px 20px;
        font-size: 14px;
    }
}
