@import "../../stylesheets/utilities";

.layout {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 5fr 8fr;
    grid-template-rows: 120px 1fr;
    grid-template-areas: "steps steps" "aside content";
    padding: 20px 0 100px;

    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: "steps" "aside" "content";
        padding: 0 0 100px;
        --box-padding: 0;
        --conten-box-border: none;
        --content-box-pad-t: 30px;
        --show-details: none;
    }

    > * {
        position: relative;
        z-index: 50;
    }

    &_steps {
        width: 100%;
        grid-area: steps;
        display: grid;
        grid-template-columns: 5fr 8fr;
        z-index: 70;

        @media screen and (max-width: 700px) {
            --steps-translateY: 0;
            --steps-pad: 0;
            display: flex;
            position: sticky;
            top: 0;
            background-color: $light_sky_blue;
        }

        > div {
            grid-column: 2/3;
            width: 100%;
            padding: var(--steps-pad, 0 40px);
            display: flex;
            justify-content: center;
            transform: translateY(var(--steps-translateY, 50%));
        }
    }

    &_aside {
        grid-area: aside;
        padding: var(--box-padding, 15px);
    }

    &_content {
        grid-area: content;
        padding: var(--box-padding, 15px);

        &_box {
            width: 100%;
            background-color: $white;
            padding: var(--content-box-pad-t, 100px) 20px 20px 20px;
            border: var(--conten-box-border, 1px solid rgba(0, 74, 143, 0.3));
            border-radius: 4px;
        }
    }

    &_bg {

        &_top, &_bottom {
            display: var(--show-details, block);
            position: absolute;
            z-index: 1;
        }

        &_top {
            top: 20%;
            right: 0;
        }

        &_bottom{
            left: 0;
            bottom: 0;
        }
    }
}
