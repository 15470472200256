@import ".././../stylesheets/utilities";

.layout {
   width: 100%;
   height: 100%;
   min-height: 70vh;
   background-color: $dark;
   display: flex;

    &_aside {
        max-width: 400px;
        background-color: $white;
        padding: 50px 0;

        @media (max-width: 700px) {
            display: none;
        }

        > img {
            width: 100%;
        }
    }

    &_content {
        position: relative;
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px var(--error-pad, 100px);
        overflow: hidden;

        @media (max-width: 700px) {
            --error-pad: var(--padding);
        }

        > img {
            max-width: 50%;
            position: absolute;
            right: 0;
            bottom: -130px;
        }
    }

    &_box {
        max-width: 300px;

        > p {
            font-size: 18px;
            font-weight: font-weight(regular);
            line-height: 24px;
            color: $white;
            padding: 20px 0;
        }
    }

    &_btn {
        width: max-content;
        display: block;
        font-size: 18px;
        font-weight: font-weight(bold);
        color: $white;
        border-radius: 4px;
        border: solid 1px $white;
        padding: 10px;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
            background-color: $white;
            color: $dark;
        }
    }

    &_title {
        font-size: clamp(80px, 15vw, 150px);
        color: $dark;
        text-shadow: 
            -1px -1px 0 $light_red,
            1px -1px 0 $light_red,
            -1px 1px 0 $light_red,
            1px 1px 0 $light_red
        ;
    }
}