::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background-color: #d9e4ee;
}

::-webkit-scrollbar-thumb {
    background-color: #003c74;
    border-radius: 5px;
}
html {
    overflow: hidden;
    height: 100%;
}
#root {
    height: 100%;
}
body {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --padding: 40px;
    --header-height: 100px;
    font-family: $main-font;
    background-color: var(--color-lighter-sky-blue);
    overflow: auto;
    padding-top: var(--header-height);

    @media #{$xsmall} {
        --padding: 1rem;
        --header-height: 70px;
    }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
input,
textarea,
button {
    font-family: $main-font;
}

button,
a {
    cursor: pointer;
}

:focus-visible {
    outline: solid 2px $blue !important;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.onlyText {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 80px var(--padding);

    .title {
        font-size: 36px;
        font-weight: font-weight(bold);
        color: $text_blue;
        margin: 60px 0 20px;
        word-break: break-word;

        @media #{$xsmall} {
            font-size: 26px;
        }
    }

    .subtitle {
        font-size: 22px;
        font-weight: font-weight(bold);
        color: $text_blue;
        margin: 30px 0 15px;
        word-break: break-word;

        @media #{$xsmall} {
            font-size: 12px;
        }
    }

    p {
        font-size: 16px;
        font-weight: font-weight(regular);
        line-height: 30px;
        margin-bottom: 10px;

        + p {
            margin-top: 30px;
        }
    }

    ul {
        font-size: 16px;
        font-weight: font-weight(regular);
        list-style-type: disc;
        margin: 0 0 0 10px;
        padding-left: 20px;

        li {
            margin-bottom: 10px;
        }
    }

    a {
        color: $text_blue;
        text-decoration: underline;
    }
}

.form {
    max-width: 400px;
    margin: 0 auto;

    &_title {
        width: 100%;
        color: $dark;
        font-size: 18px;
        font-weight: font-weight(bold);
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    &_item {
        width: 100%;
        margin-bottom: 15px;
        position: relative;

        &.danger {
            --outline-color: red;

            &:hover {
                --msgerror-opacity: 1;
            }
        }
    }

    .fieldset,
    fieldset {
        padding: 0;
    }

    input,
    textarea,
    select {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        background-color: $light_sky_blue;
        font-size: 16px;
        padding: 10px 20px;
        border: solid 1px var(--outline-color, transparent);

        &:focus {
            outline: solid 1px var(--outline-color, $dark_blue);
        }
    }

    label,
    .label {
        width: 100%;
        font-size: 14px;
        font-weight: font-weight(bold);
        text-transform: uppercase;
        color: $dark_blue;
        margin-bottom: 10px;
    }

    .columns-1x1 {
        display: grid;
        grid-template-columns: 1fr 1fr;

        > * + * {
            padding-left: 15px;
        }
    }

    .columns-2x1 {
        display: grid;
        grid-template-columns: 2fr 1fr;

        > * + * {
            padding-left: 15px;
        }
    }

    .btn-group {
        width: 100%;
        display: flex;
        justify-content: var(--group-justify, space-between);
        padding: 15px 0;
    }

    .btn-end {
        --group-justify: flex-end;
    }
}

.cool {
    --card-bg: #d9e4ee;
    --card-border-color: #004a8f;
    --card-color: #004a8f;
    --badge-bg: #d9e4ee;
    --badge-color: #004a8f;
    --icon-color: #004a8f;
}

.warning {
    --card-bg: #ffe5c9;
    --card-border-color: #ff9a28;
    --card-color: #ff9a28;
    --badge-bg: #ffe5c9;
    --badge-color: #ff9a28;
    --icon-color: #ff9a28;
}

.danger {
    --card-bg: #f7d8d5;
    --card-border-color: #e06658;
    --card-color: #e06658;
    --badge-bg: #f7d8d5;
    --badge-color: #e06658;
    --icon-color: #e06658;
}

.success {
    --card-bg: #daf6e9;
    --card-border-color: #039b54;
    --card-color: #039b54;
    --badge-color: #039b54;
    --badge-bg: #daf6e9;
    --icon-color: #039b54;
}

.color {
    &.compass {
        --mainColor: #2a90d0;
    }
    &.alertonEngineering {
        --mainColor: #05c46b;
    }
    &.alertonProgramming {
        --mainColor: #071a25;
    }
    &.default {
        --mainColor: #071a25;
    }
}
@media print {
    header,
    footer {
        display: none;
    }
}
